<template>
    <section class="mr-2">
        <div class="row mx-0 px-3 bg-white br-12">
            <div class="col-12 mt-4">
                <p class="f-18 text-general f-500 mb-3">Cuentas por cobrar en los próximos 2 meses</p>
                <graficaMeses />
            </div>
        </div>
        <div class="row mx-0 px-3 mt-3">
            <div class="col text-left">
                <p class="f-18 f-500 text-general">Créditos vencidos</p>
            </div>
        </div>
        <div class="row mx-0 my-3 align-items-start justify-content-xl-center">
            <div v-for="(data, idx) in vencidos" :key="idx" class="card-credito bg-white m-2 p-2">
                <div class="row mx-0 mb-2 bb-red">
                    <i :class="`f-25 text-general2 ${data.icon}`" />
                    <div class="col px-2 text-general">
                        {{ data.nombre }}
                    </div>
                </div>
                <p class="text-center text-general f-600">
                    {{ data.nombre!='Total'?data.valor: separadorNumero(data.valor) }}
                </p>
            </div>
            <div class="card-deuda bg-white m-2 p-2">
                <div class="row mx-0 mb-2 py-1 align-items-end">
                    <div class="br-red px-2">
                        <i class="f-25 text-general2 icon-cash" />
                        <p class="text-general f-500">Deuda</p>
                    </div>
                    <div class="col overflow-hidden text-general" :class="!drop2?'h-deuda':''">
                        <div v-for="(deuda, idx) in creditosVencidos.deuda" :key="idx" class="row mx-0 mb-2 justify-content-between">
                            <p>{{ deuda.texto }}</p>
                            <p>{{ separadorNumero(deuda.valor) }}</p>
                        </div>
                    </div>
                    <i class="icon-angle-down f-18 text-general cr-pointer" @click="drop2=!drop2" />
                </div>
            </div>
        </div>
        <div class="row mx-0 px-3">
            <div class="col text-left">
                <p class="f-18 f-500 text-general">Créditos activos</p>
            </div>
        </div>
        <div class="row mx-0 my-3 align-items-start justify-content-xl-center">
            <div v-for="(data, idx) in activos" :key="idx" class="card-credito bg-white m-2 p-2">
                <div class="row mx-0 mb-2 bb-blue">
                    <i :class="`f-25 text-general2 ${data.icon}`" />
                    <div class="col px-2 text-general">
                        {{ data.nombre }}
                    </div>
                </div>
                <p class="text-center text-general f-600">
                    {{ data.nombre!='Total'?data.valor: separadorNumero(data.valor) }}
                </p>
            </div>
            <div class="card-deuda bg-white m-2 p-2">
                <div class="row mx-0 mb-2 py-1 align-items-end">
                    <div class="br-blue px-2">
                        <i class="f-25 text-general2 icon-cash" />
                        <p class="text-general f-500">Deuda</p>
                    </div>
                    <div class="col overflow-hidden text-general" :class="!drop1?'h-deuda':''">
                        <div v-for="(deuda, idx) in creditosVigentes.deuda" :key="idx" class="row mx-0 mb-2 justify-content-between">
                            <p>{{ deuda.texto }}</p>
                            <p>{{ separadorNumero(deuda.valor) }}</p>
                        </div>
                    </div>
                    <i class="icon-angle-down f-18 text-general cr-pointer" @click="drop1=!drop1" />
                </div>
            </div>
        </div>

        <div class="row mx-0 px-3 text-general f-500 align-items-center">
            <!-- <div class="d-sm-none d-xl-block" style="width:300px;" /> -->
            <div class="col text-left px-0">
                <p class="f-18">{{$config.vendedor + 's'}} con créditos activos</p>
            </div>
            <div class="d-middle">
                <p class="f-18">Suma de la deuda</p>
                <div class="border-black bg-white p-2 px-3 br-10 ml-3">
                    <p class="f-18"> {{ separadorNumero(datosGenerales.sumaActivos) }}</p>
                </div>
            </div>
        </div>
        <div class="row mx-0 my-3 justify-content-lg-center">
            <div class="d-sm-none d-xl-block" style="width:89px;" />
            <router-link v-for="(data, idx) in tenderosActivos.slice(0,5)" :key="idx" class="card-tendero td-none bg-white text-general m-2 p-2" :to="{ name: 'admin.tiendas.produccion.creditos.tenderoVer', params: { id_tienda: data.id_tienda } }">
                <div class="row mx-0 mb-2 pb-2 bb-blue">
                    <img :src="data.logo_mini" alt="" class="border bg-white" />
                    <div class="col-8 px-0">
                        <p class="f-600 text-general tres-puntos">{{ data.nombre }}</p>
                    </div>
                </div>
                <div class="row mx-0 justify-center">
                    debe
                    <p class="text-general f-600 px-2">
                        {{ separadorNumero(data.total) }}
                    </p>
                </div>
            </router-link>
            <div v-show="tenderosActivos.length > 5" class="col-auto bg-general3 text-white br-17 m-2 d-middle-center flex-column cr-pointer" @click="tenderosCredito(1)">
                <p>Ver más</p>
                <i class="icon-angle-right f-18" />
            </div>
        </div>
        <div v-show="tenderosActivos.length <= 0" class="row mx-0">
            <div class="col text-center text-muted">
                <p>No existen {{ $config.vendedor + 's' }} con cŕeditos activos  </p>
            </div>
        </div>
        <div class="row mx-0 px-3 pl-3 text-general f-500 align-items-center">
            <div class="col px-0 text-left">
                <p class="f-18">{{ $config.vendedor + 's'}}  morosos</p>
            </div>
            <div class="d-middle">
                <p class="f-18">Suma de la deuda</p>
                <div class="border-black bg-white p-2 px-3 br-10 ml-3">
                    <p class="f-18">{{ separadorNumero(datosGenerales.sumaMorosos) }}</p>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3 justify-content-lg-center">
            <div class="d-sm-none d-xl-block" style="width:89px;" />
            <router-link v-for="(data, idx) in tenderosMorosos.slice(0,5)" :key="idx" class="card-tendero td-none bg-white text-general m-2 p-2" :to="{ name: 'admin.tiendas.produccion.creditos.tenderoVer', params: { id_tienda: data.id_tienda } }">
                <div class="row mx-0 mb-2 pb-2 bb-blue">
                    <img :src="data.logo_mini" alt="" class="border bg-white" />
                    <div class="col-8 px-0">
                        <p class="f-500 tres-puntos">{{ data.nombre }}</p>
                    </div>
                </div>
                <div class="row text-general mx-0 justify-center">
                    debe
                    <p class="f-600 px-2">
                        {{ separadorNumero(data.total) }}
                    </p>
                    <p />
                </div>
                <div class="row mx-0 justify-center align-items-center">
                    <p class="pr-2 text-general">
                        {{ data.fecha_menor_cuota }}
                    </p>
                    <el-tooltip class="item" effect="light" content="Fecha en la que debería realizar el pago" placement="right-end">
                        <i class="icon-attention-alt text-danger f-18" />
                    </el-tooltip>
                </div>
            </router-link>
            <div v-show="tenderosMorosos.length > 5" class="col-auto bg-general3 text-white br-17 mx-2 d-middle-center cr-pointer" @click="tenderosCredito(2)">
                <p>Ver más</p>
                <i class="icon-angle-right f-18" />
            </div>
        </div>
        <div v-show="tenderosMorosos.length <= 0" class="row mx-0">
            <div class="col text-center text-muted">
                <p>No existen {{ this.$config.vendedor + 's' }} morosos </p>
            </div>
        </div>
        <modal-ver-tenderos ref="verTenderos" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    components: {
        modalVerTenderos: () => import('./partials/modalVerTenderos'),
        graficaMeses: () => import('./components/graficaMeses')
    },
    data(){
        return {
            drop1: false,
            drop2: false,
            tenderos: [
                { nombre: 'Cantidad de tenderos', icon: 'icon-storefront-outline', valor: 202000 },
                { nombre: 'Cantidad de pedidos', icon: 'icon-receipt', valor: 202000 },
                { nombre: 'Cantidad de cuotas', icon: 'icon-format-list-checks', valor: 202000 },
                { nombre: 'Total', icon: 'icon-storefront-outline', valor: 202000 },
            ]
        }
    },
    computed:{
        ...mapGetters({
            creditosVencidos: 'almacen/credito/creditosVencidos',
            creditosVigentes: 'almacen/credito/creditosVigentes',
            tenderosMorosos: 'almacen/credito/tenderosMorosos',
            tenderosActivos: 'almacen/credito/tenderosActivos',
            datosGenerales: 'almacen/credito/datosGenerales',
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        vencidos(){
            return[
                { nombre: 'Cantidad de tenderos', icon: 'icon-storefront-outline', valor: this.creditosVencidos.cant_tenderos },
                { nombre: 'Cantidad de pedidos', icon: 'icon-receipt', valor: this.creditosVencidos.cant_pedidos },
                { nombre: 'Cantidad de cuotas', icon: 'icon-format-list-checks', valor: this.creditosVencidos.cant_cuotas },
                { nombre: 'Total', icon: 'icon-storefront-outline', valor: this.creditosVencidos.total  },
            ]
        },
        activos(){
            return[
                { nombre: 'Cantidad de tenderos', icon: 'icon-storefront-outline', valor: this.creditosVigentes.cant_tenderos },
                { nombre: 'Cantidad de pedidos', icon: 'icon-receipt', valor: this.creditosVigentes.cant_pedidos },
                { nombre: 'Cantidad de cuotas', icon: 'icon-format-list-checks', valor: this.creditosVigentes.cant_cuotas },
                { nombre: 'Total', icon: 'icon-storefront-outline', valor: this.creditosVigentes.total  },
            ]
        }
    },
    watch: {
        vencidos(){
            this.vencidos[0].nombre = `Cantidad de ${this.$config.vendedor}`
        },
        activos(){
            this.activos[0].nombre = `Cantidad de ${this.$config.vendedor}`
        }
    },
    mounted(){

        this.listarDatosGraficas()
    },
    methods: {
        tenderosCredito(t){
            this.$refs.verTenderos.toggle(t)
        },
        listarDatosGraficas(){
            this.$store.commit('almacen/credito/set_grafica_dos_meses', [])
            const params = {
                id_cedis: this.id_cedis,
                id_moneda: this.id_moneda,
                tendero: 1
            }
            this.$store.dispatch('almacen/credito/action_listar_creditos_grafica_dos_meses', params)
        }
    }
}
</script>

<style lang="scss" scoped>
.h-deuda{
    height: 50px;
}
.br-17{
    border-radius: 17px;
}
.border-blue-ligh{
    border: 1px solid #0892DD;
}
.card-credito{
    width: 180px;
    min-height: 90px;
    border-radius: 17px;
    font-size: 14px;
    box-shadow: 0 1px 3px 0 #00000029;
    .bb-blue{
        border-bottom: 1px solid var(--color-db);
    }
    .bb-red{
        border-bottom: 1px solid var(--color-db);
    }
}
.card-tendero{
    width: 190px;
    border-radius: 17px;
    font-size: 14px;
    box-shadow: 0 1px 3px 0 #00000029;
    .bb-blue{
        border-bottom: 1px solid var(--color-db);
    }
    .bb-red{
        border-bottom: 1px solid #FF0000;
    }
    img{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        left: -18px;
    }
}
.card-deuda{
    width: 360px;
    border-radius: 17px;
    font-size: 14px;
    box-shadow: 0 1px 3px 0 #00000029;
    .br-blue{
        border-right: 1px solid var(--color-db);
    }
    .br-red{
        border-right: 1px solid var(--color-db);
    }
}
</style>
